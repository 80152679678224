<template>
  <v-form ref="form">
  <!-- COMPANY PROFILE -->
  <div v-if="user && user.role === 'client'">
      <p :class="label">
        Company Profile
        <span class="red--text">*</span>
      </p>
      <v-select 
        @change="$emit('updateProfile', $event)"
        :rules="[rules.required]"
        :items="profiles"
        item-text="name"
        item-value="id"
        outlined
        dense
      ></v-select>
    </div>

    <div
      v-for="field in orderBy(fields, 'order', 'asc')"
      :key="field.id"
    >
      <!-- IF TEXT FIELD -->
      <div v-if="field.type == 'text_field'">
        <p :class="label">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
        </p>
        <div v-if="field.description" :class="description">{{ field.description }}</div>
        
        <v-text-field
          v-model="field.value"
          :rules="field.required ? [rules.required] : []"
          outlined
          dense
        ></v-text-field>
      </div>
      
      <!-- IF TEXT AREA -->
      <div v-if="field.type == 'textarea'">
        <p :class="label">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
        </p>
        <div v-if="field.description" :class="description">{{ field.description }}</div>

        <v-textarea
          v-model="field.value"
          :rules="field.required ? [rules.required] : []"
          outlined
        ></v-textarea>
      </div>
      
      <!-- IF DROPDOWN -->
      <div v-if="field.type == 'dropdown'">
        <p :class="label">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
        </p>
        <div v-if="field.description" :class="description">{{ field.description }}</div>

        <v-select
          v-model="field.value"
          :items="field.options"
          :rules="field.required ? [rules.required] : []"
          :clearable="!field.required"
          outlined
          dense
        ></v-select>
      </div>
      
      <!-- IF MULTI SELECT -->
      <div v-if="field.type == 'multi-select'">
        <p :class="label">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
        </p>
        <div v-if="field.description" :class="description">{{ field.description }}</div>

        <v-combobox
          v-model="field.value"
          :items="field.options"
          :rules="field.required ? [rules.required] : []"
          :clearable="!field.required"
          outlined
          dense
          persistent-hint
          small-chips
          multiple
        ></v-combobox>
      </div>
      
      <!-- IF CHECKBOX -->
      <div v-if="field.type == 'checkbox'">
        <p :class="label">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
        </p>
        <div v-if="field.description" :class="description">{{ field.description }}</div>

        <div class="mb-5 d-flex gap-12 align-center">
          <v-radio-group 
            v-model="field.value"
            :rules="[field.required ? rules.filled : rules.none]"
            class="mt-0"
          >
            <v-checkbox
              v-for="(option, i) in field.options"
              :key="i"
              :label="option"
              :value="option"
              v-model="field.value"
              class="ma-0 mr-6"
              color="primary"
              hide-details
              dense
            ></v-checkbox>
          </v-radio-group>
        </div>
      </div>
      
      <!-- IF RADIO -->
      <div v-if="field.type == 'radio'">
        <p :class="label">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
        </p>
        <div v-if="field.description" :class="description">{{ field.description }}</div>

        <div class="mb-4">
          <v-radio-group
            v-model="field.value"
            :rules="field.required ? [rules.required] : []"
          >
            <v-radio
              v-for="(option, i) in field.options"
              :key="i"
              :label="option"
              :value="option"
              class="ma-0 mr-6"
              color="primary"
              hide-details
              dense
            ></v-radio>
          </v-radio-group>
        </div>
      </div>
      
      <!-- IF HEADER -->
      <div v-if="field.type == 'header'">
        <h6 class="font-weight-bold" style="font-size: 18px !important">
          {{ field.label }}
          <span v-if="field.description" style="font-size: 15px !important" class="font-weight-medium grey--text"><br>{{ field.description }}</span>
        </h6>
        <v-divider class="my-6"></v-divider>
      </div>

      <!-- IF ATTACHMENT -->
      <div v-if="field.type == 'attachment'">
        <p :class="label">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
        </p>
        <div v-if="field.description" :class="description">{{ field.description }}</div>

        <v-scale-transition
          v-if="field.value && field.value.length"
          class="d-flex flex-wrap gap-12 mb-5"
          tag="div"
          group
        >
          <file-block
            v-for="(attachment, i) in field.value"
            :key="i"
            :file="attachment"
            @remove="removeFile(field, i)"
          />
        </v-scale-transition>

        <v-file-input
          v-model="field.value"
          :rules="field.required ? [rules.required] : []"
          prepend-inner-icon="mdi-upload"
          prepend-icon=""
          multiple
          outlined
          dense
        ></v-file-input>
      </div>

      <!-- IF DATE PICKER -->
      <div v-if="field.type == 'date-picker'">
        <p :class="label">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
        </p>
        <div v-if="field.description" :class="description">{{ field.description }}</div>

        <v-menu
          v-model="datePickerMenu[field.id]"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateFormattedMomentjs(field.value)"
              clearable
              label="Date"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="field.value = null"
              dense
              outlined
              :rules="field.required ? [rules.required] : []"
              prepend-icon="mdi-calendar"
              persistent-hint
            ></v-text-field>
          </template>
          <v-date-picker
            :key="field.id"
            v-model="field.value"
            @change="datePickerMenu[field.id] = false"
            dense
            outline
          ></v-date-picker>
        </v-menu>
      </div>

      <!-- IF TIME PICKER -->
      <div v-if="field.type == 'time-picker'">
        <p :class="label">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
        </p>
        <div v-if="field.description" :class="description">{{ field.description }}</div>

        <v-menu
        v-model="timePickerMenu[field.id]"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290"
        min-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="timeFormattedMomentjs(field.value)"
            label="Time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            scrollable
            :rules="field.required ? [rules.required] : []"
            v-bind="attrs"
            v-on="on"
            outlined
            dense
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="timePickerMenu[field.id]"
          v-model="field.value"
          full-width
          @change="timePickerMenu[field.id] = false"
          format="ampm"
        ></v-time-picker>
      </v-menu>
      </div>
    </div>

    <!-- BUTTON -->
    <div v-if="preview" class="d-flex gap-7">
      <v-btn @click="validateForm" color="primary">Submit</v-btn>
      <v-btn @click="resetForm" color="primary" outlined>Reset</v-btn>
    </div>

    <v-card v-if="submitted" class="mt-5" dark>
      <v-card-text>
        <div 
          class="mb-3" 
          v-for="(field, i) in fields" :key="i"
          :class="{ 'd-none' : field.type == 'header' }"
        >
          <pre class="font-weight-bold accent--text">{{ field.label }}</pre>
          <pre class="d-inline-block text-pre-wrap">{{ formatValue(field.value) }}</pre>
        </div>
      </v-card-text>

    </v-card>
  </v-form>
</template>

<script>
import rules from '@/rules'
import { join, orderBy } from 'lodash'
import camelCase from 'lodash/camelCase'
import moment from 'moment'
// import { format, parseISO } from 'date-fns'

import FileBlock from './FileBlockSimple'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      orderBy,
      camelCase,
      result: [],
      submitted: false,
      label: 'secondary--text mb-2 label',
      description: 'darken--text text--disabled mb-2 mt-n1',
      datePickerMenu: [],
      timePickerMenu: [],
      date: null, // format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
      time: null
    }
  },

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    user: Object,
    fields: Array,
    profiles: Array,
    preview: Boolean,
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    FileBlock
  },

  /*------------------------------------------------------------------------------
   * METHODS`
   *----------------------------------------------------------------------------*/
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        this.submitted = true
      }
      else {
        console.log('form has error')
      }
    },

    resetForm() {
      this.$refs.form.reset()
    },

    formatValue(value) {
      if (typeof value == 'object')
        return join(value, '\n')
      else
        return value
    },

    getFileName(file) {
      return file.name.length > 20 ? `${file.name.substr(0, 17)}...` : file.name
    },

    removeFile(field, i) {
      field.value.splice(i, 1)
    },

    getFileSize(file) {
      return file.size
    },

    dateFormattedMomentjs (value) {
      return value ? moment(value).format('dddd, MMMM Do YYYY') : ''
    },

    timeFormattedMomentjs (value) {
      const date = moment().format("M-DD-YYYY")
      
      return value ? moment(date + ' ' + value).format('h:mm A') : ''
    }
  }
}
</script>

<style lang="scss">
.label {
  line-height: 1.4;
}
</style>