<template>
  <v-card
    width="270"
    outlined
  >
    <v-btn
      v-if="!$store.state.requestmessage.status.uploading && !$store.state.request.status.uploading"
      @click="$emit('remove')"
      class="mr-n6 mt-n3"
      depressed
      absolute
      x-small
      right
      fab
    >
      <v-icon small>mdi-close</v-icon>
    </v-btn>

    <v-card-text class="d-flex gap-7 align-center">
      <v-icon :color="$fileIcon(file.type, true)" left>{{ $fileIcon(file.type) }}</v-icon> 
      <div class="d-inline-block text-truncate" style="max-width: 150px;">
        <span>{{ file.name }}</span>
        <span class="caption info--text"><br> {{ file.size | fileSize }} ({{ file.type }})</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['file'],
}
</script>